@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap');

.main_texts {
    margin-left: 13%;
    margin-right: 13%;
    height: 150px;
    display: block;
    position: relative;
    width: 74%;
}

.main_texts > span{
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.main_texts:first-of-type {
    margin-top: 0;
}

.brand_page {
    text-align: center;
    color: white;
    font-family: 'Roboto' sans-serif;
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    overflow: auto;
    display: block;
    min-height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.brand_container {
    min-height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

.brand_header{
    padding-top: 12px;
    line-height: 11vw;
    height: 11vw;
}

.brand_header > img {
    vertical-align: middle;
}

.footer_text {
    position: absolute;
    font-size: 14px;
    margin: 0;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%);
    opacity: 0.7;
}
.micro_img {
    cursor: pointer;
    width: 130px;
    filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
}

.micro_img.pre_micro {
    animation: 1s ease-in 1s infinite alternate zoom_in_and_out;
}

.micro_container {
    z-index: 1;
    position: relative;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.micro_label {
    font-style: italic;
    font-size: 22px;
    margin-top: 10px;
    margin: 0;
}

.loading_micro {
    opacity: 0.5;
}

.finished_micro {
    transform: scale(0);
}

@keyframes zoom_in_and_out {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
}

.wave1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    display: block;
    width: 300px;
    height: 300px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}

.wave2 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 200px;
    height: 200px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}


.wave_container {
    position: absolute;
    left: 50%;
    bottom: 50%;
    z-index: -1;
    transform: scale(0);
    transform-origin: middle center;
    transition: transform 0.6s ease;
}

.wave_appear {
    z-index: -1;
    transform: scale(1);
}

@media (max-width: 850px) {
    .post_recording {
        margin-bottom: 100px;
    }
}

.post_module {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    transition: transform 0.5s ease, height 0.5s ease, opacity 0.5s ease;
}

.module_appear {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.email_input {
    margin-left: auto;
    margin-right: auto;
}

.post_module > .send_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    width: 100px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}
.email_input > input {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    font-family: 'Roboto' sans-serif;
    font-weight: 100;
    color: white;
    line-height: 32px;
    font-size: 19px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
}

.email_input > input {
    display: inline-block;
    width: 70%;
}
.email_input > input:focus {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
    outline: 0;
}

.label_title {
    font-weight: 400;
    font-size: 25px;
}
.email_input > input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.thank_you > p {
    margin: 20px;
}

.thank_you > img {
    filter: invert(100%) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.14));
    width: 80px;
}

.star_container {
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.star_container > .rating_star {
    max-width: 80px;
    stroke: white;
    stroke-width: 2px;
    display: inline-block;
    width: 18%;
    margin: 1%;
}

.google_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 24px;
    height: 35px;
    line-height: 35px;
    width: 200px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.post_recording > div:last-of-type {
    padding-bottom: 170px;
}