@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(250, 250, 250);
  font-size: 17px;
}

.App {
  display: block;
  background-color: white;
  width: 60%;
  padding: 100px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  margin-bottom: 10vh;
}

h1 {
  font-size: 30px;
  color: rgb(60, 60, 60);
}

.main_img {
  margin-top: 20px;
  width: 40%;
}

.description {
  margin-top: 20px;
  margin-bottom: 60px;
}

.field {
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
}

.field > .input_wrapper {
  vertical-align: top;
  margin: 0;
  display: inline-block;
  width: 40%;
}

.field > .field_name {
  margin: 0;
  font-weight: 300;
  font-size: 20px;
  display: inline-block;
  width: 35%;
  margin-right: 4%;
}

.input_wrapper > input {
  width: 100%;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  line-height: 35px;
  padding-left: 15px;
  font-size: 16px;
}

.input_wrapper > input:focus {
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 0px 0px 10px rgba(90, 131, 185, 0.3);
  outline: none;
}

.input_wrapper > .input_label {
  text-align: left;
  margin: 0;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 10px;
}

.dic_cat {
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

.dic_cat > .field {
  display: inline-block;
  width: 39%;
}
.dic_cat > .dic_name {
  display: inline-block;
  width: 29%;
  margin-right: 10%;
}
.dic_cat > .dic_words {
  display: inline-block;
  width: 60%;
}

.field_comment {
  text-align: justify;
  font-size: 14px;
  font-weight: 300;
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.send_button {
  cursor: pointer;
  display: block;
  width: 60%;
  color: white;
  background-color: #5252A3;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 50px;
  font-weight: 400;
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease;
}

.save_button {
  color: darkblue;
  text-decoration: underline;
  cursor: pointer;
}

.send_button:hover {
  background-color: #5C5CAD;
}

.dict {
  margin-bottom: 60px;
}

.not_validated > input{
  box-shadow: 0px 0px 15px rgba(170, 0, 0, 0.7);
}

/* MENU */

.pageSwitcher {
  position: relative;
  display: flex;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  background-color: white;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  margin-top: 30px;
  overflow: hidden;
}

.pageSwitcher > .page_button {
  cursor: pointer;
  width: 50%;
  text-align: center;
  z-index: 2;
  color: black;
  transition: color 0.2s linear;
}

.pageSwitcher > .pageSwitcherOverlay {
  position: absolute;
  background-color: #5252A3;
  width: 50%;
  height: 100%;
  transition: left 0.2s linear;
}

.pageSwitcher > .selected {
  color: white;
}

#Dashboard.selected~.pageSwitcherOverlay {
  left: 50%;
}

#WebApp.selected~.pageSwitcherOverlay {
  left: 0%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main_texts {
    margin-left: 13%;
    margin-right: 13%;
    height: 150px;
    display: block;
    position: relative;
    width: 74%;
}

.main_texts > span{
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main_texts:first-of-type {
    margin-top: 0;
}

.brand_page {
    text-align: center;
    color: white;
    font-family: 'Roboto' sans-serif;
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    overflow: auto;
    display: block;
    min-height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.brand_container {
    min-height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

.brand_header{
    padding-top: 12px;
    line-height: 11vw;
    height: 11vw;
}

.brand_header > img {
    vertical-align: middle;
}

.footer_text {
    position: absolute;
    font-size: 14px;
    margin: 0;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%);
    opacity: 0.7;
}
.micro_img {
    cursor: pointer;
    width: 130px;
    -webkit-filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
            filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
}

.micro_img.pre_micro {
    -webkit-animation: 1s ease-in 1s infinite alternate zoom_in_and_out;
            animation: 1s ease-in 1s infinite alternate zoom_in_and_out;
}

.micro_container {
    z-index: 1;
    position: relative;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.micro_label {
    font-style: italic;
    font-size: 22px;
    margin-top: 10px;
    margin: 0;
}

.loading_micro {
    opacity: 0.5;
}

.finished_micro {
    transform: scale(0);
}

@-webkit-keyframes zoom_in_and_out {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
}

@keyframes zoom_in_and_out {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
}

.wave1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    display: block;
    width: 300px;
    height: 300px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}

.wave2 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 200px;
    height: 200px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}


.wave_container {
    position: absolute;
    left: 50%;
    bottom: 50%;
    z-index: -1;
    transform: scale(0);
    transform-origin: middle center;
    transition: transform 0.6s ease;
}

.wave_appear {
    z-index: -1;
    transform: scale(1);
}

@media (max-width: 850px) {
    .post_recording {
        margin-bottom: 100px;
    }
}

.post_module {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    transition: transform 0.5s ease, height 0.5s ease, opacity 0.5s ease;
}

.module_appear {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.email_input {
    margin-left: auto;
    margin-right: auto;
}

.post_module > .send_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    width: 100px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}
.email_input > input {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    font-family: 'Roboto' sans-serif;
    font-weight: 100;
    color: white;
    line-height: 32px;
    font-size: 19px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
}

.email_input > input {
    display: inline-block;
    width: 70%;
}
.email_input > input:focus {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
    outline: 0;
}

.label_title {
    font-weight: 400;
    font-size: 25px;
}
.email_input > input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}
.email_input > input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}
.email_input > input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}
.email_input > input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.thank_you > p {
    margin: 20px;
}

.thank_you > img {
    -webkit-filter: invert(100%) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.14));
            filter: invert(100%) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.14));
    width: 80px;
}

.star_container {
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.star_container > .rating_star {
    max-width: 80px;
    stroke: white;
    stroke-width: 2px;
    display: inline-block;
    width: 18%;
    margin: 1%;
}

.google_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 24px;
    height: 35px;
    line-height: 35px;
    width: 200px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.post_recording > div:last-of-type {
    padding-bottom: 170px;
}
