@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(250, 250, 250);
  font-size: 17px;
}

.App {
  display: block;
  background-color: white;
  width: 60%;
  padding: 100px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  margin-bottom: 10vh;
}

h1 {
  font-size: 30px;
  color: rgb(60, 60, 60);
}

.main_img {
  margin-top: 20px;
  width: 40%;
}

.description {
  margin-top: 20px;
  margin-bottom: 60px;
}

.field {
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
}

.field > .input_wrapper {
  vertical-align: top;
  margin: 0;
  display: inline-block;
  width: 40%;
}

.field > .field_name {
  margin: 0;
  font-weight: 300;
  font-size: 20px;
  display: inline-block;
  width: 35%;
  margin-right: 4%;
}

.input_wrapper > input {
  width: 100%;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  line-height: 35px;
  padding-left: 15px;
  font-size: 16px;
}

.input_wrapper > input:focus {
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 0px 0px 10px rgba(90, 131, 185, 0.3);
  outline: none;
}

.input_wrapper > .input_label {
  text-align: left;
  margin: 0;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 10px;
}

.dic_cat {
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

.dic_cat > .field {
  display: inline-block;
  width: 39%;
}
.dic_cat > .dic_name {
  display: inline-block;
  width: 29%;
  margin-right: 10%;
}
.dic_cat > .dic_words {
  display: inline-block;
  width: 60%;
}

.field_comment {
  text-align: justify;
  font-size: 14px;
  font-weight: 300;
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.send_button {
  cursor: pointer;
  display: block;
  width: 60%;
  color: white;
  background-color: #5252A3;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 50px;
  font-weight: 400;
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease;
}

.save_button {
  color: darkblue;
  text-decoration: underline;
  cursor: pointer;
}

.send_button:hover {
  background-color: #5C5CAD;
}

.dict {
  margin-bottom: 60px;
}

.not_validated > input{
  box-shadow: 0px 0px 15px rgba(170, 0, 0, 0.7);
}

/* MENU */

.pageSwitcher {
  position: relative;
  display: flex;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  background-color: white;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  margin-top: 30px;
  overflow: hidden;
}

.pageSwitcher > .page_button {
  cursor: pointer;
  width: 50%;
  text-align: center;
  z-index: 2;
  color: black;
  transition: color 0.2s linear;
}

.pageSwitcher > .pageSwitcherOverlay {
  position: absolute;
  background-color: #5252A3;
  width: 50%;
  height: 100%;
  transition: left 0.2s linear;
}

.pageSwitcher > .selected {
  color: white;
}

#Dashboard.selected~.pageSwitcherOverlay {
  left: 50%;
}

#WebApp.selected~.pageSwitcherOverlay {
  left: 0%;
}